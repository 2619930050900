import Image from 'next/legacy/image';
import { PMPKeyVisualViewProps } from 'lib/themes/themes';

import styles from './PMPKeyVisualView.module.css';
import { getImageUrl } from 'lib/util/imageUrl';

const PMPKeyVisualView = ({
  bannerImageUrl,
  bannerTitle,
  bannerDescription,
}: PMPKeyVisualViewProps) => {
  return (
    <section className={styles['list-mainvisual']}>
      <div className={styles['list-mainvisual__pic']}>
        <ul>
          <li>
            <Image
              priority={true}
              className={styles['mainvisual__pic']}
              layout="fill"
              src={bannerImageUrl}
              loader={({ src, width }) => getImageUrl(src, width)}
              alt="banner"
            />
          </li>
        </ul>
      </div>
      <div className={styles['list-mainvisual__circle']}>
        <div className={styles['list-mainvisual__circle__bg']}></div>
        <svg
          className={styles['list-mainvisual__circle__border']}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 800 800"
        >
          <g>
            <path
              d="M669.3,642.9c-5.5,6.1-11.2,11.9-17,17.6c-1.2,1.2-1.2,3.3,0,4.6c1.3,1.3,3.3,1.4,4.6,0.2
              c6-5.8,11.8-11.8,17.3-18c1.2-1.3,1-3.4-0.4-4.6C672.5,641.6,670.5,641.6,669.3,642.9z"
            ></path>
            <path
              d="M737.9,541c-1.7-0.7-3.6,0-4.3,1.6c-12.4,29-28.6,56.3-48.1,81.1c-1.1,1.4-0.8,3.4,0.6,4.6
              c1.4,1.1,3.5,1,4.6-0.4c19.8-25.2,36.3-53.1,48.9-82.6C740.4,543.6,739.6,541.7,737.9,541z"
            ></path>
            <path
              d="M756.2,484.6c-1.8-0.4-3.6,0.6-4,2.3c-2.3,9.5-5.1,18.9-8.2,28.2c-0.5,1.6,0.4,3.5,2.1,4.1
              c1.7,0.6,3.6-0.3,4.2-2c3.2-9.5,5.9-19.1,8.3-28.8C759.1,486.7,758,485,756.2,484.6z"
            ></path>
            <path
              d="M764.3,435.8c-1.8-0.2-3.5,1.1-3.6,2.8c-0.8,7.9-1.9,15.8-3.3,23.6c-0.3,1.7,0.9,3.4,2.7,3.7
              c1.8,0.3,3.5-0.8,3.8-2.6c1.4-8,2.5-16,3.3-24C767.5,437.6,766.1,436,764.3,435.8z"
            ></path>
            <path
              d="M681.2,593.9c-1.5-1-3.5-0.8-4.4,0.5c-3.1,4.4-6.3,8.7-9.5,12.9c-1,1.3-0.6,3.2,0.8,4.4c1.4,1.1,3.4,1,4.5-0.3
              c3.3-4.3,6.6-8.7,9.7-13.1C683.2,596.9,682.8,595,681.2,593.9z"
            ></path>
            <path
              d="M723.8,498c-7.9,26.2-19.1,51.5-33.1,75c-0.8,1.4-0.2,3.3,1.3,4.2c1.6,1,3.5,0.6,4.4-0.8
              c14.3-24,25.7-49.8,33.8-76.5c0.5-1.6-0.6-3.3-2.3-3.8C726.1,495.6,724.3,496.5,723.8,498z"
            ></path>
            <path
              d="M732.9,476.9c1.8,0.4,3.6-0.6,3.9-2.2c1.1-5.2,2.2-10.4,3.1-15.6c0.3-1.6-1-3.2-2.8-3.5
              c-1.8-0.3-3.5,0.8-3.8,2.4c-0.9,5.1-1.9,10.2-3,15.3C729.9,474.9,731.1,476.5,732.9,476.9z"
            ></path>
            <path
              d="M378,762c-8.2-0.5-16.3-1.3-24.4-2.3c-1.7-0.2-3.3,1.1-3.6,2.9c-0.3,1.8,1,3.5,2.7,3.7
              c8.3,1,16.5,1.8,24.8,2.3c1.8,0.1,3.3-1.3,3.4-3.2C381,763.7,379.7,762.1,378,762z"
            ></path>
            <path
              d="M496.5,749.7c-30.4,8.4-61.9,12.7-93.4,13c-1.7,0-3.1,1.5-3.2,3.3c0,1.8,1.4,3.3,3.2,3.3
              c32.1-0.3,64.1-4.7,95.1-13.2c1.7-0.5,2.7-2.3,2.2-4.1C500,750.3,498.2,749.2,496.5,749.7z"
            ></path>
            <path
              d="M551.7,729.5c-8.9,4.1-18,7.8-27.2,11.2c-1.6,0.6-2.5,2.5-1.8,4.2c0.6,1.7,2.5,2.7,4.1,2.1
              c9.4-3.4,18.6-7.2,27.7-11.4c1.6-0.7,2.3-2.7,1.5-4.4C555.2,729.5,553.3,728.8,551.7,729.5z"
            ></path>
            <path
              d="M594.7,706.1c-6.7,4.3-13.5,8.3-20.5,12.1c-1.5,0.8-2.1,2.8-1.2,4.4c0.9,1.6,2.8,2.3,4.4,1.4
              c7.1-3.9,14-8,20.9-12.4c1.5-1,1.9-3,0.9-4.5C598.1,705.6,596.1,705.1,594.7,706.1z"
            ></path>
            <path
              d="M404.6,744.9c5.5-0.1,10.9-0.3,16.4-0.6c1.7-0.1,2.9-1.7,2.8-3.5c-0.1-1.8-1.5-3.2-3.2-3.1
              c-5.3,0.3-10.7,0.5-16,0.6c-1.6,0-2.9,1.5-2.9,3.4C401.6,743.4,403,744.9,404.6,744.9z"
            ></path>
            <path
              d="M446.8,741.8c27.7-3.8,54.9-10.9,80.9-21.3c1.5-0.6,2.2-2.5,1.5-4.2c-0.7-1.7-2.5-2.6-4-2
              c-25.5,10.2-52.2,17.2-79.3,20.9c-1.6,0.2-2.7,1.9-2.5,3.7C443.7,740.7,445.2,742,446.8,741.8z"
            ></path>
            <path
              d="M562.4,696.8c-4.6,2.5-9.2,4.9-13.8,7.2c-1.5,0.7-2,2.6-1.2,4.3c0.8,1.7,2.6,2.4,4.1,1.7
              c4.8-2.3,9.4-4.8,14.1-7.3c1.5-0.8,1.9-2.8,1-4.4C565.7,696.6,563.9,696,562.4,696.8z"
            ></path>
            <path
              d="M89.8,588c-0.9-1.5-2.9-1.9-4.5-1c-1.6,0.9-2.1,2.9-1.2,4.5c4.3,7.1,8.9,14.1,13.7,20.9c1,1.5,3.1,1.7,4.6,0.7
              c1.5-1.1,1.9-3.1,0.9-4.5C98.5,601.8,94,595,89.8,588z"
            ></path>
            <path
              d="M186.8,693.4c-25.5-18.5-48.5-40.4-68.4-64.9c-1.1-1.3-3.1-1.5-4.6-0.4c-1.4,1.1-1.7,3.2-0.6,4.6
              c20.2,24.9,43.7,47.2,69.7,66c1.4,1,3.5,0.7,4.5-0.8C188.5,696.5,188.2,694.5,186.8,693.4z"
            ></path>
            <path
              d="M237,724.1c-8.8-4.4-17.3-9.2-25.7-14.3c-1.5-0.9-3.5-0.4-4.4,1.2c-1,1.6-0.5,3.6,1,4.5
              c8.5,5.2,17.3,10.1,26.2,14.6c1.6,0.8,3.5,0.1,4.3-1.6C239.1,726.8,238.5,724.8,237,724.1z"
            ></path>
            <path
              d="M282.1,743.1c-7.5-2.6-14.9-5.4-22.2-8.5c-1.6-0.7-3.5,0.1-4.2,1.8c-0.7,1.7,0,3.6,1.6,4.3
              c7.5,3.1,15,6,22.7,8.6c1.7,0.6,3.5-0.4,4.1-2.1C284.6,745.5,283.7,743.6,282.1,743.1z"
            ></path>
            <path
              d="M143.9,631c1.1,1.2,3.1,1.2,4.5,0c1.4-1.3,1.6-3.2,0.5-4.4c-3.6-4-7.1-8-10.5-12.2c-1-1.3-3-1.3-4.5-0.2
              c-1.4,1.1-1.8,3.1-0.7,4.4C136.7,622.9,140.2,627,143.9,631z"
            ></path>
            <path
              d="M162.2,645.2c-1.3,1.3-1.4,3.3-0.2,4.5c20.2,19.3,42.8,36.2,67.1,50c1.4,0.8,3.3,0.2,4.2-1.4
              c0.9-1.6,0.5-3.5-0.9-4.4c-23.8-13.6-46-30.1-65.8-49.1C165.4,643.7,163.5,643.9,162.2,645.2z"
            ></path>
            <path
              d="M270.7,716.3c0.7-1.7,0-3.6-1.4-4.2c-4.8-2-9.5-4.1-14.2-6.4c-1.5-0.7-3.3,0.1-4.1,1.7
              c-0.8,1.7-0.3,3.6,1.2,4.3c4.8,2.3,9.6,4.4,14.5,6.5C268.2,718.9,270,718,270.7,716.3z"
            ></path>
            <path
              d="M47.8,300.1c1.8,0.5,3.6-0.4,4.1-2.1c2.3-7.8,4.9-15.6,7.7-23.3c0.6-1.6-0.3-3.5-2-4.1
              c-1.7-0.7-3.6,0.2-4.2,1.8c-2.9,7.8-5.5,15.7-7.8,23.7C45,297.8,46,299.6,47.8,300.1z"
            ></path>
            <path
              d="M37.7,416.3c-1.4-31.5,1.3-63.2,8-93.9c0.4-1.7-0.8-3.4-2.6-3.8c-1.8-0.4-3.6,0.7-3.9,2.4
              c-6.8,31.4-9.5,63.6-8.1,95.7c0.1,1.8,1.6,3.1,3.5,3C36.3,419.5,37.8,418,37.7,416.3z"
            ></path>
            <path
              d="M45,474.6c-2-9.6-3.6-19.3-4.8-29c-0.2-1.7-1.9-3-3.7-2.7c-1.8,0.2-3.1,1.8-2.9,3.6c1.2,9.9,2.9,19.8,4.9,29.5
              c0.4,1.7,2.1,2.8,3.9,2.4C44.2,478,45.4,476.3,45,474.6z"
            ></path>
            <path
              d="M51,499c-0.5-1.7-2.3-2.6-4.1-2.2c-1.8,0.5-2.8,2.3-2.4,4c2.2,7.8,4.7,15.5,7.4,23.1c0.6,1.7,2.5,2.5,4.2,1.9
              c1.7-0.6,2.6-2.5,2.1-4.1C55.6,514.2,53.2,506.7,51,499z"
            ></path>
            <path
              d="M62.5,347.3c1.8,0.3,3.5-0.8,3.8-2.4c0.9-5.3,1.9-10.5,3-15.8c0.3-1.6-0.8-3.2-2.6-3.6
              c-1.8-0.4-3.5,0.6-3.9,2.2c-1.1,5.3-2.1,10.7-3,16.1C59.4,345.4,60.6,347,62.5,347.3z"
            ></path>
            <path
              d="M65.8,452.2c-4.2-27.1-5.1-54.8-2.7-82.1c0.1-1.6-1.2-3.1-3-3.2c-1.8-0.2-3.4,1-3.6,2.7
              c-2.4,27.9-1.5,56.1,2.8,83.7c0.3,1.6,1.9,2.7,3.8,2.4C64.8,455.4,66,453.8,65.8,452.2z"
            ></path>
            <path
              d="M70.6,477.3c-0.4-1.6-2.1-2.5-3.9-2.1c-1.8,0.4-3,2-2.6,3.6c1.2,5.2,2.5,10.3,4,15.4c0.5,1.6,2.3,2.5,4,1.9
              c1.8-0.5,2.8-2.2,2.4-3.8C73.1,487.4,71.8,482.4,70.6,477.3z"
            ></path>
            <path
              d="M283.6,49.4c-7.9,2.6-15.7,5.5-23.4,8.7c-1.6,0.7-2.4,2.6-1.7,4.3c0.7,1.7,2.6,2.5,4.2,1.9
              c7.6-3.1,15.2-5.9,23-8.5c1.7-0.5,2.5-2.4,2-4.1C287.1,49.8,285.3,48.8,283.6,49.4z"
            ></path>
            <path
              d="M161.4,126.9c23.7-20.7,50.1-38.4,78.4-52.3c1.6-0.8,2.2-2.7,1.4-4.4c-0.8-1.7-2.7-2.4-4.3-1.6
              c-28.8,14.2-55.7,32.2-79.8,53.3c-1.3,1.2-1.4,3.2-0.2,4.6C158,127.8,160.1,128,161.4,126.9z"
            ></path>
            <path
              d="M115.8,169.3c1.4,1.2,3.5,1,4.6-0.3c6.2-7.6,12.8-14.9,19.6-21.9c1.2-1.2,1.1-3.3-0.2-4.6
              c-1.3-1.3-3.4-1.3-4.6-0.1c-7,7.2-13.6,14.6-20,22.3C114.1,166.1,114.4,168.2,115.8,169.3z"
            ></path>
            <path
              d="M104.2,184.4c-1.5-1.1-3.5-0.8-4.6,0.6c-4.7,6.6-9.2,13.3-13.5,20.2c-0.9,1.5-0.4,3.5,1.2,4.5
              c1.6,1,3.6,0.5,4.5-0.9c4.2-6.7,8.6-13.3,13.2-19.8C106,187.5,105.7,185.5,104.2,184.4z"
            ></path>
            <path
              d="M250.3,92.9c-0.8-1.7-2.7-2.4-4.1-1.7c-4.9,2.4-9.7,5-14.5,7.6c-1.4,0.8-1.9,2.8-1,4.4
              c0.9,1.6,2.8,2.2,4.2,1.4c4.7-2.6,9.4-5.1,14.2-7.5C250.6,96.4,251.1,94.5,250.3,92.9z"
            ></path>
            <path
              d="M150.8,171.3c18.6-20.2,39.6-38.2,62.4-53.3c1.3-0.9,1.6-2.9,0.6-4.4c-1-1.5-2.9-2.1-4.3-1.1
              c-23.3,15.5-44.7,33.8-63.7,54.4c-1.1,1.2-0.9,3.2,0.5,4.4C147.7,172.4,149.7,172.5,150.8,171.3z"
            ></path>
            <path
              d="M133.5,186.3c-1.4-1.2-3.4-1-4.5,0.3c-3.3,4.2-6.5,8.4-9.5,12.7c-1,1.3-0.5,3.3,1,4.4c1.5,1.1,3.5,0.8,4.4-0.5
              c3-4.2,6.1-8.4,9.4-12.5C135.2,189.5,134.9,187.5,133.5,186.3z"
            ></path>
            <path
              d="M601.5,90.5c-7-4.5-14.1-8.9-21.4-12.9c-1.5-0.9-3.5-0.3-4.4,1.4c-0.9,1.6-0.4,3.6,1.2,4.4
              c7.1,4,14.1,8.2,21,12.7c1.5,0.9,3.5,0.5,4.5-1C603.4,93.5,603,91.4,601.5,90.5z"
            ></path>
            <path
              d="M464.8,43.1c31,5.6,61.3,15.3,89.8,28.7c1.6,0.7,3.5,0,4.3-1.6c0.8-1.7,0.2-3.6-1.4-4.4
              c-29-13.7-59.9-23.6-91.4-29.2c-1.7-0.3-3.4,0.9-3.7,2.7C461.9,41.1,463.1,42.8,464.8,43.1z"
            ></path>
            <path
              d="M406.3,37.4c9.8,0.2,19.6,0.7,29.4,1.7c1.7,0.2,3.3-1.2,3.5-3c0.2-1.8-1.1-3.5-2.8-3.6
              c-9.9-1-19.9-1.5-29.9-1.7c-1.8,0-3.2,1.4-3.2,3.3C403.2,35.8,404.6,37.3,406.3,37.4z"
            ></path>
            <path
              d="M357.4,39.8c7.9-0.9,15.8-1.6,23.7-2c1.7-0.1,3.1-1.6,3-3.5c-0.1-1.8-1.6-3.3-3.3-3.2
              c-8.1,0.4-16.1,1.1-24.2,2c-1.8,0.2-3,1.9-2.8,3.7C354.1,38.7,355.7,40,357.4,39.8z"
            ></path>
            <path
              d="M546.8,91.5c0.8-1.7,0.2-3.6-1.3-4.3c-4.9-2.3-9.9-4.5-15-6.5c-1.5-0.6-3.3,0.3-4,2c-0.7,1.7,0,3.6,1.5,4.2
              c4.9,2,9.8,4.2,14.7,6.4C544.1,93.9,546,93.1,546.8,91.5z"
            ></path>
            <path
              d="M507.9,75.8c0.6-1.7-0.2-3.6-1.8-4.1c-26.6-8.6-54.3-13.9-82.2-15.8c-1.7-0.1-3.1,1.3-3.2,3.1
              c-0.1,1.8,1.1,3.4,2.7,3.5c27.4,1.9,54.5,7.1,80.6,15.5C505.6,78.6,507.3,77.6,507.9,75.8z"
            ></path>
            <path
              d="M400.9,58.4c0-1.8-1.3-3.3-3-3.3c-5.3,0-10.6,0.2-15.9,0.5c-1.7,0.1-2.9,1.7-2.8,3.5c0.1,1.8,1.5,3.2,3.1,3.2
              c5.2-0.3,10.4-0.4,15.6-0.4C399.6,61.8,400.9,60.3,400.9,58.4z"
            ></path>
            <path
              d="M786.1,311.9c-6.6-28.9-16.4-57.1-29.3-83.8c-25.7-53.4-63.6-100.8-109.9-137.7c-46.2-37-101-63.5-158.8-76.5
              c-57.8-13.1-118.4-13.1-176.2,0C254,26.9,199.3,53.4,153.1,90.4c-46.3,36.9-84.2,84.3-109.9,137.7c-12.9,26.7-22.7,54.9-29.3,83.8
              C7.3,340.8,4,370.4,4,400c0,29.6,3.3,59.2,9.8,88.1c6.6,28.9,16.4,57.1,29.3,83.8c25.7,53.4,63.6,100.8,109.9,137.7
              c46.3,37,101,63.5,158.8,76.5c57.8,13.1,118.4,13.1,176.2,0c57.8-13.1,112.6-39.5,158.8-76.5c46.3-36.9,84.2-84.3,109.9-137.7
              c12.9-26.7,22.7-54.8,29.3-83.8c6.6-28.9,9.8-58.5,9.8-88.1C796,370.4,792.7,340.8,786.1,311.9z M779.6,486.6
              c-6.5,28.4-16.1,56.1-28.8,82.3c-25.3,52.5-62.6,99.1-108.1,135.4c-45.5,36.4-99.3,62.4-156.1,75.3c-56.9,12.9-116.4,12.9-173.3,0
              c-56.9-12.9-110.6-38.9-156.1-75.3C111.7,668.1,74.4,621.5,49.1,569c-12.7-26.2-22.3-53.9-28.8-82.3c-6.5-28.4-9.7-57.5-9.7-86.6
              c0-29.1,3.2-58.2,9.7-86.6c6.5-28.4,16.1-56.1,28.8-82.3c25.3-52.5,62.6-99.1,108.1-135.4c45.5-36.4,99.3-62.4,156.1-75.3
              c56.9-12.9,116.4-12.9,173.3,0c56.9,12.9,110.6,38.9,156.1,75.3c45.5,36.3,82.8,82.9,108.1,135.4c12.7,26.2,22.3,53.9,28.8,82.3
              c6.5,28.4,9.7,57.5,9.7,86.6C789.3,429.1,786.1,458.2,779.6,486.6z"
            ></path>
            <path
              d="M760.6,337.2c-1.8,0.3-3,2-2.8,3.7c1.3,8.1,2.4,16.2,3.1,24.3c0.2,1.7,1.8,3,3.6,2.9c1.8-0.2,3.2-1.7,3-3.5
              c-0.8-8.3-1.9-16.5-3.2-24.7C764.2,338.1,762.5,336.9,760.6,337.2z"
            ></path>
            <path
              d="M752.9,316.2c0.4,1.7,2.2,2.7,4,2.3c1.8-0.4,2.9-2.1,2.5-3.9c-7.4-31.2-18.9-61.5-34.1-89.7
              c-0.8-1.6-2.8-2.1-4.5-1.2c-1.6,0.9-2.2,2.8-1.4,4.4C734.4,255.8,745.7,285.5,752.9,316.2z"
            ></path>
            <path
              d="M704.4,202.8c0.9,1.5,3,1.8,4.5,0.9c1.6-1,2-3,1.1-4.5c-5.4-8.4-11.2-16.5-17.3-24.4c-1.1-1.4-3.1-1.6-4.6-0.5
              c-1.4,1.1-1.7,3.2-0.7,4.5C693.4,186.6,699.1,194.6,704.4,202.8z"
            ></path>
            <path
              d="M671.4,159.4c1.1,1.3,3.2,1.4,4.6,0.2c1.4-1.2,1.6-3.3,0.4-4.6c-5.4-6-10.9-11.9-16.7-17.6
              c-1.3-1.2-3.3-1.2-4.6,0.1c-1.3,1.3-1.3,3.4-0.1,4.6C660.7,147.7,666.1,153.5,671.4,159.4z"
            ></path>
            <path
              d="M732.7,322.4c1.8-0.4,2.9-2.1,2.6-3.7c-1.3-5.3-2.7-10.6-4.2-15.8c-0.5-1.6-2.3-2.4-4-1.9
              c-1.8,0.5-2.8,2.2-2.4,3.8c1.5,5.1,2.9,10.3,4.1,15.5C729.2,321.8,730.9,322.8,732.7,322.4z"
            ></path>
            <path
              d="M716.6,280.7c0.6,1.5,2.4,2.2,4.2,1.6c1.7-0.6,2.7-2.4,2.1-4c-9.9-26.2-22.9-51.1-38.8-74.1
              c-0.9-1.4-2.9-1.6-4.4-0.5c-1.5,1.1-2,3-1,4.3C694.1,230.6,706.9,255,716.6,280.7z"
            ></path>
            <path
              d="M663.2,187.5c1,1.3,3,1.4,4.4,0.2c1.4-1.1,1.8-3.1,0.7-4.4c-3.3-4.1-6.8-8.2-10.3-12.1c-1.1-1.2-3.1-1.2-4.5,0
              c-1.4,1.2-1.6,3.2-0.5,4.4C656.6,179.5,659.9,183.5,663.2,187.5z"
            ></path>
          </g>
        </svg>
        <div className={styles['list-mainvisual__circle__txt']}>
          <div className={styles['list-mainvisual__circle__txt__ttl']}>{bannerTitle}</div>
          <p className={styles['list-mainvisual__circle__txt__desc']}>{bannerDescription}</p>
        </div>
      </div>
    </section>
  );
};

export default PMPKeyVisualView;
